/*
*   ==================================================
*   Layout / Header Stylesheet
*   <header />
*   ==================================================
*/



/*
*   =========================
*   Container
*   =========================
*/
header {
    position: absolute;
    left: 0;

    width: 100%;
}



/*
*   =========================
*   Mobile Menu
*   =========================
*/
header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .burger-menu, .notifications {
        padding: $spacing;

        width: 2rem;
        height: 2rem;

        background-image: url(../../../../public/assets/menu.svg);
        background-size: 2rem;
        background-position: center;
        background-repeat: no-repeat;

        cursor: pointer;
    }

    .logo {
        width: 7rem;
        height: 2.5rem;

        background-image: url(../../../../public/assets/logo.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .notifications {
        position: relative;

        background-image: url(../../../../public/assets/bell.svg);
        background-size: 1.8rem;

        &::after {
            content: "";

            position: absolute;
            right: 1rem;
            bottom: 1rem;

            width: 0.5rem;
            height: 0.5rem;

            background-color: $color-primary;
            border: 2px solid $color-white;
            border-radius: 1rem;
        }
    }
}

header .dropdown-menu {
    display: block;

    padding: 0;
    margin: 0;

    list-style-type: none;
    
    background-color: white;

    li {
        padding: $spacing;

        color: $color-primary;
        font-weight: 600;

        &.spacer {
            padding: 0;
            margin: $spacing-sm $spacing;

            height: 2px;

            background-color: $color-grey-1;
            border-radius: 1rem;
        }

        &.login {
            color: $color-white;

            background-color: $color-primary;
        }
    }
}



/*
*   =========================
*   Desktop Overrides
*   =========================
*/
@include for-desktop-up {
    header {
        .main-menu {
            display: flex;

            padding: 0;
            margin: 0;

            list-style-type: none;

            li {
                padding: calc($spacing / 2) $spacing;

                cursor: pointer;

                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
}