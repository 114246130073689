/*
*   ==================================================
*   Layout Stylesheet
*   ==================================================
*/



body {
    color: $color-body-text;

    background-color: $color-body-background;
}



/*
*   =========================
*   Sections (<section />)
*   =========================
*/
section {
    .section-title {
        position: relative;
        
        font-size: 2rem;
        
        & + .section-body {
            margin-top: $spacing;
        }
    }

    .section-body {
        position: relative;
    }
}